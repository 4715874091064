// Common
@import '../assets/scss/_common/common';

/* CSS MAQ Home Logado */

//components
@import '../../components/c-header/c-header';
@import '../../components/c-home-hero/c-home-hero';

@import '../../components/c-rewards-banner-discount/c-rewards-banner-discount';
@import '../../components/c-top-destinations/c-top-destinations';
@import '../../components/c-hotels-in-world/c-hotels-in-world';
@import '../../components/c-our-brands/c-our-brands';
@import '../../components/c-more-options-home/c-more-options-home';
@import '../../components/c-hotel-sheet-email/c-hotel-sheet-email';
@import '../../components/c-cookies-disclaimer/c-cookies-disclaimer';
@import '../../components/c-hotel-sheet-map-hotel/c-hotel-sheet-map-hotel';
@import '../../components/c-image-info/c-image-info';
@import '../../components/c-cookies/_c-cookies';
@import '../../components/c-modal-cookies2/c-modal-cookies2';

@import '../../components/c-footer/_c-footer';
@import '../../components/c-footer/_c-brands';
@import '../../components/c-error-page/_c-error-page';
.c-error-page .c-error-page__photo__title{
    font-size:46px;
}
.add-more-telephones-copy{
    .more{
        display:none;
    }
    .less{
        display:block;

    }
    .see-more{
        .more{
            display:block;
        }
        .less{
            display:none;

        }
    }
}
.c-footer{
    margin-bottom:46px;
    .social-apps{
        border-bottom:1px solid $color-gray;
        padding-bottom:30px;
        .u-social-icons{
            h5{
                display:none;
                text-transform: uppercase;
                font-size: 1em;
                font-weight: 700;
                margin-bottom: 15px;
            }
        }
    }
}
// Common - utilities:
@import "../assets/scss/_common/7-utilities/_utilities";
