
  /*on off switch*/
  .onoffswitch {
    position: relative;
     width: 81px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #FFFFFF; border-radius: 16px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "SI";
    padding-left: 10px;
    background-color: $brand-primary; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "NO";
    padding-right: 10px;
    background-color: #EEEEEE; color: #999999;
    text-align: right;
}
.onoffswitch-inner:after{

}
.onoffswitch-checkbox .onoffswitch-switch {
    background: #333333;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    background: #ffffff ;
}
.onoffswitch-checkbox + .onoffswitch-label .onoffswitch-switch {
    background: #bfb5b5 ;
    border:none;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 6px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 47px;
    border: 2px solid #FFFFFF; border-radius: 16px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}


#popup-cookies .modal-footer .btn+.btn{
    position:absolute;
    right:15px;
}
#popup-cookies .modal-footer .btn.cancel{
    float:left;
}
#popup-cookies .modal-header{
    background-color:#333333;
    color:#ffffff;
    padding-left:30px;
    text-transform: uppercase;
    padding:20px;
}
#popup-cookies .popup-cookies-pre-text {
    padding: 10px 20px;
    font-size: 20px;
    line-height: normal;
}
#popup-cookies .modal-body ul{
    text-transform: initial !important;
}
#popup-cookies ul{
    background-color:#fbfafa;
    text-transform: lowercase;
    border-bottom:none !important;
    text-align: center;
    font-size:16px;
}
#popup-cookies ul li{
    border-bottom:2px solid #eeeeee;
    padding-top:10px;
}
#popup-cookies ul .cookies-title{
    color:#282d3a;
    font-weight: bold;
}
#popup-cookies ul .cookies-text{
    color:#332c29;
}
#popup-cookies ul{
    list-style:none;
    padding-left:0px !important;
}
#popup-cookies ul .switch-btn{   
    position: relative;
    width: 80px;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom:15px;
}
#popup-cookies{
    z-index: 10000000001;
}
/*.modal-backdrop{
    z-index: 10400;
}*/