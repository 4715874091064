// Fondo blanco para elementos comunes
.o-module {
    &--white {
        background-color: $color-white;
    }
    // marcas
    &--level {
        background-color: $color-level-bg;
    }
    &--redlevel {
        background-color: $color-redlevel-bg;
    }
    &--me {
        background-color: $color-me-bg;
    }
    &--royal {
        background-color: $color-royal-bg;
    }
    &--family {
        background-color: $color-family-bg;
    }
    h3 {
        margin: 45px 0 20px 0;
        font-size: 30px;
    }
    &--border-top {
        border-top: 1px solid $color-dark-gray;
    }
    &--bg-email {
        padding: 50px 0;
        background-position: bottom;
        background-size: cover;
        background-image: url("/assets/img/melia.com/section-email.jpg");
        overflow: hidden;
    }
    .title-mr {
        padding: 10px;
        font-size: 24px;
    }

}
@media (max-width: 768px) {
    .o-module h3 {
        margin: 30px 0 10px 0;
    }
}

// Fondo blanco
.bg-white {
    background-color: $color-white;
}

.img-responsive {
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
}