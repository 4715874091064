.c-cookies-footer{
    position:fixed;
    bottom:0;
    width:100%;
    background-color:#ffffff;
    z-index: 999;
    padding: 12px 30px;
  }
  .c-cookies-footer-container, .c-cookies-footer-container2{
    /* padding:30px; */
    display:table;
    width: 100%;
  }
  .c-cookies-footer-container2{
    margin-top:5px;
  }
  .c-cookies-footer-container > *{
    display:table-cell;
    vertical-align: middle;
  }
  .c-cookies-footer-text{
    width:80%;
  }
  .c-cookies-footer-button{
    width:20%;
    text-align: center;
    margin: auto;
  }
  .c-cookies-footer .btn-accept{
   display: block;
   color:#707078;
   text-transform:uppercase;
   border:1px solid #707078;
   width: 180px;
   box-sizing:border-box;
   padding: 15px 40px;
   display: table;
   text-align: center;
   margin: auto;
   font-size: 15px;
   line-height: 17px;
   -webkit-transition: color 1s ease-out;
   -moz-transition: color 1s ease-out;
   -o-transition: color 1s ease-out;
   transition: color 1s ease-out;
  }
  .c-cookies-footer .btn-accept:hover{
    color:$brand-primary;
  }
  .c-cookies-footer .btn-accept span{
    display: inline;
      white-space: normal;
      line-height: 17px;
  }
  .c-cookies-footer .c-cookies-footer-text{
    color:#707078;
    font-size: 12px;
    line-height: 21px;
  }
 
  /* .pol-cookies,.pref-cookies{ display:table-cell;}
 .pol-cookies{
    width:80%;
  }
  .pref-cookies{
    width:20%;
    text-align:center;
  }*/
  .pol-cookies a,.pref-cookies a{
    background-color:#efebeb;
    padding: 10px 10px;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
    font-size:12px;
    float: left;
    margin-right:10px !important;
  }
  .pol-cookies a:hover,.pref-cookies a:hover{
    background-color:#c8c6c6;
  }
  .pol-cookies{text-align:left;}
  .pref-cookies{/* text-align:right; */}

  @media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .c-cookies-footer-text,.c-cookies-footer-button{
      width:100%;
      display:block;
      &.c-cookies-footer-text{
        line-height: 18px;
        .c-cookies-footer-container2{
          margin-bottom:10px;
          a{
            background:none !important;
            text-transform: none;
            font-weight: normal;
            padding:0px;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        
      }
      &.c-cookies-footer-button{
        .btn-accept{
          padding:10px;
          width:auto;
          font-size:12px;
        }
      }
    }
  }