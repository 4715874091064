
// Utilidad necesaria para cuando se inserta un formulario dentro de una modal.
// Se utiliza en un div dentro del "modal-body" envolviendo el formulario.
.u-modal-wrapper {
	padding: 20px;
}


// 5 columns
.u-col-xs-5ths,
.u-col-sm-5ths,
.u-col-md-5ths,
.u-col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.u-col-xs-5ths {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
    .u-col-sm-5ths {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .u-col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .u-col-lg-5ths {
        width: 20%;
        float: left;
    }
}

// 5 columns para footer
.u-col-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;    

    i { float: right; }
}
@media (min-width: $screen-sm-min) {
    .u-col-5ths {
        float: left;
        width: 20%;

        i { display: none; }
    }
}
@media (max-width: $screen-xs-max) {
    .u-col-5ths { 
        width: 100%;

        h5 {
            border-bottom: 1px solid $color-border-gray;
        }

        h5[aria-expanded="true"]{
            
            i::before {
                display: inline-block;
                transform: rotate(180deg);
            }
        }
    }
}


// Utilidad para centrar los btn de Redes Sociales
.u-social-icons {
    margin: 28px 0;
    text-align: center;

    ul {
        padding-left: 0;
        list-style: none;
        margin-left: -5px;

        li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    i {
        background-color: $color-dark-gray;
        color: $color-white;
        border-radius: 25px;
        padding: 7px;
        font-size: 21px;
        transition: background-color .3s;

        &.icon-twitter:hover { background-color: $color-twitter; }
        &.icon-facebook:hover { background-color: $color-facebook; }
        &.icon-google:hover { background-color: $color-google; }
        
        &.icon-envelope:hover,
        &.icon-printer:hover { 
            //background-color: $color-black;
            background-color: $brand-primary-hover;
        }
    }
}

// Utilidad para centrar los btn de Redes Sociales
.u-social-icons-menu {
    float: right;
    padding-top: 10px;
    padding-bottom: 4px;

    ul {
        padding: 0;
        list-style: none;
        margin-left: -5px;

        li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;

            a { text-decoration: none; }
        }
    }

    i {
        color: $color-white;
        border-radius: 25px;
        padding: 8px;
        font-size: 22px;

        &.icon-twitter { background-color: $color-twitter; }
        &.icon-facebook { background-color: $color-facebook; }
        &.icon-google { background-color: $color-google; }
    }
}

// Utilidad para centrar los btn para descargar las Apps
.u-download-apps {
    text-align: center;

    .container { 
        padding-bottom: 25px;
        border-bottom: 1px solid $color-gray;
    }

    h5 {
        text-transform: uppercase;
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 15px;
    }

    a {
        margin: 0 7px;
    }

    img {
        max-width: 135px;
    }
}

// Utilidad para poner links en línea centrados
.u-links-inline {
    text-align: center;

    a{
        display: inline-block;
        margin-right: 10px;
        text-align: center;
    }
}

// Utilidad para los links legales del footer
.u-copyright {
    text-align: center;

    img { margin: 25px 0; }

    .logo { 
        margin: 25px 0 30px;
        
        i { 
            font-size: 50px;   

            .path1::before { color: $color-black; }
            .path2::before { color: $color-black; }
        }
    }

    a { margin-bottom: 25px; }
}

//Sobreescribimos temporalmente el quitar las mayúsculas a los títulos
//de las modales y le damos mayor interlineado
.modal-body {
    h3 { text-transform: none; }

    ul li { line-height: 30px; }
}

// Separación hacia arriba
.u-top-separator {
    margin-top: 30px;
}

// Utilidad para el footer
.c-footer .u-social-icons i {
    padding: 10px;
    font-size: 25px;
}


// Utilidad para posicionar las migas de pan o los títulos cuando no hay hero.
.u-without-hero {
    margin-top: 50px;
}