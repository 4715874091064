// Component for displaying a list of brands.
.c-brands {
    background-color: $color-light-gray;
    padding: 15px 0;
    text-align: center;
    
    ul {
        display: inline-block;
        margin: 0;
    }

    li {
        display: inline-block;
        padding: 0 0.4em;
        
        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
	}
	
    &__separator {
        border-left: 1px solid $color-gray;
        padding-left: 2em !important;
    }

    .c-brands__link { 
        display: inline-block; 

        .icon {
            &-granmelia--logo-effect,
            &-melia--logo-effect,
            &-paradisus--logo-effect {
                position: relative;
                top: -14px;
            }
            &-me--logo-effect,
            &-innside--logo-effect,
            &-rewards--logo-effect {
                position: relative;
                top: -7px;
            }
            &-tryp--logo-effect {
                position: relative;
                top: -12px;
            }
            &-sol--logo-effect {
                position: relative;
                top: -10px;
            }
        }
	}
	
	&--less-margin { margin-top: 40px; }
}

@media (max-width: $screen-xs-max) {
    .c-brands {
        .c-brands__link { display: block; }

        ul {
            display: block;

            li {
                display: block;
                width: 50%;
                height: 60px;
                padding: 0;

                &:nth-child(even) { float: right }
                &:nth-child(odd)  { float: left }
                &:nth-child(7) { width: 100% }
                
                i { top: initial !important }
            }
        }

        &__separator { 
            clear: both;
            float: left !important;
            padding: 5px 0 0 !important;
            border-left: none;
            border-top: 1px solid $color-gray;
        }

        li.icon-right {            
            float: right !important;
            padding: 15px 0 0 !important;
            border-top: 1px solid $color-gray;
        }

        &__link, &__link i {
            &, &::before { font-size: 45px }
        }
        i.icon-me--logo-effect::before,
        i.icon-circle-vertical--logo-effect,
        i.icon-rewards--logo-effect::before { font-size: 65px }
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .c-brands__separator {
        margin-left: 0.6em;
        padding-left: 1em !important;
    }
}

@media (min-width: 800px) {
    .c-brands li { padding: 0 0.5em; }
}

@media (min-width: $screen-md-min) {
    .c-brands li { padding: 0 1.3em; }
}

@media (min-width: $screen-lg-min){
    .c-brands li { padding: 0 1.3em; }
}