//Top Destinations Component
.c-top-destinations {
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;

  a {
    display: block;
    color: initial;
  }

  h3 {
    font-weight: bold;
    text-align: center;
  }

  &__hotel {
    margin: 10px 0;
    position: relative;

    &--image {
      position: relative;
      overflow: hidden;

      img {
        height: 220px;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        font-family: "object-fit: cover;";
      }

      .caption {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 1;

        .caption-inner {
          height: 100%;

          .middle-content {
            position: absolute;
            margin-top: -16.5px;
            width: 100%;
            top: 50% !important;
            left: 0;

            h3 {
              margin: 0;
              color: $color-white;
              font-size: 30px;
              font-weight: 700;
              text-align: center;
            }

            h4 {
              margin: 5px 0 0 0;
              color: $color-white;
              font-size: 18px;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    &--ribbon {
      position: absolute;
      top: 50px;
      background-color: #0075c0;
      left: -5px;
      padding: 5px;
      opacity: 1;
      z-index: 2;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.33);
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        border: 5px solid transparent;
        border-left-color: #015593;
        transform: scale(0.7) translateY(73%) rotate(-45deg);
      }
      & strong {
        color: white;
      }
    }
    &--footer {
      padding: 10px;
      background-color: $color-white;
      text-align: center;

      h5 {
        padding: 11px 0;
        margin: 0 0 8px;
        border-bottom: 1px solid $color-gray;
        font-size: 17px;
        font-weight: 700;
        height: 77px;
        text-transform: uppercase;
      }

      .offer {
        color: $color-black;
        padding: 4px 0;
        border-bottom: 1px solid $color-gray;

        .from {
          margin-right: 5px;
          margin-bottom: 3px;
          text-transform: uppercase;
        }

        .price {
          font-size: 29px;
          font-weight: 600;
          line-height: 1.25;
        }

        .pax {
          display: inline-block;
          text-align: left;
          line-height: 0.9;

          span {
            display: block;
          }
        }
      }

      button {
        margin-top: 10px;
      }
    }

    &--all-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover .caption {
      padding: 7px;
      background-color: transparent;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .c-top-destinations {
    &__hotel--footer .btn {
      width: 100%;
    }
  }
}
