//Home - Banner Rewards with discount
.c-rewards-banner-discount {
    padding: 20px 0;

    &__discount {
        text-align: center;
        line-height: 1.3;
        margin-right: 5%;
        display: inline-block;
        vertical-align: middle;

        .circle {
            display: inline-block;
            width: 145px;
            height: 145px;
            background-color: #0075C0;
            color: #FFF;
            font-weight: 400;
            text-align: center;
            border-radius: 145px;

            .to-tag {
                display: block;
                margin: 25px 0 -10px 0;
                font-size: 16px;
                color: #FFF;
                font-weight: 400;
                text-align: center;
            }

            .price {
                display: inline-block;
                font-size: 65px;
                letter-spacing: -3px;
            }

            .half-size {
                display: inline-block;
                margin-left: -10px;
                font-size: 0.5em;
            }
        }
    }

    &__content {
        width: calc(100% - (145px + 5%));
        display: inline-block;
        vertical-align: middle;

        .image {
            margin-bottom: 15px;
            border-bottom: 1px solid $color-gray;
        }

        .title {
            margin: 0 0 10px 0;
            font-size: 25px;
            text-transform: uppercase;
            line-height: 1.1;
        }

        .text {
            margin-bottom: 10px;
            font-size: 16px;
            text-transform: uppercase;
            line-height: 20px;
        }

        .footer-content {
			margin-bottom: 25px;

            .button {
				float: left;
                padding-left: 0;
            }
            
            .links {
				display: inline-block;
				margin-top: 5px;
				margin-left: 15px;
				text-transform: uppercase;
				
				p { margin-bottom: 2px; }
            }
        }
    }

    // Fix the intraspace added by inline-blocks.
    .row > .col-sm-10 { font-size: 0 }
    &__content .footer-content .links { font-size: 1.4rem }
}

@media (max-width: $screen-xs-max) {
    .c-rewards-banner-discount {
        
        &__discount,
        &__content {
            display: block;
            width: auto;
            float: none;
            text-align: center;
        }
        &__content { padding-top: 5px }   

        &__discount .circle {
            width: 145px;
            height: 145px;
        }

        &__content .footer-content .button { float: none }
        
        &__content .footer-content .button .btn { width: 100% }
        
        &__content .footer-content .links {
            margin-left: 0;
            margin-top: 10px;
        }
        
        &__content .footer-content .links p { display: inline-block }

        &__content .footer-content .links a { margin-left: 5px }
    }

}