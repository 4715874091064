// Hotels in world
.c-hotels-in-world {
    
    h3 { text-align: center;}

    .tab-content { padding: 15px 0; }

    /* Versión obsoleta, actualizada por flex.
    Fix caused because of different sizes not being calculated properly.    
    .nav > li {
        &:nth-child(1) { width: 30%; }
        &:nth-child(2) { width: 22.5%; }
        &:nth-child(3) { width: 25%; }
        &:nth-child(4) { width: 22.5%; }
    }*/

    .nav { 
        display: flex;
        align-items: center;
    }
    .nav > li {
        flex: 1 1;
        padding: 0 7.5px;
    }
}