html,body{
	width:100%;
	height:100%;
	margin:0;
	padding:0;
}
.full-size{
	width:100%;
	height:100%
}
//fix for https://booking.melia.com/es/new/buscar/busqueda-avanzada.htm?
header + #too-hero .too-slider {
    overflow: visible !important;
}
//end fix
main #too-hero{
	width:100%;
	height:100%;
	position:relative;
	/*@media only screen and (max-width: 1024px) { 
		#too-be{
			top:50%;	
		}
		height:60vh;
		.too-slider{
			min-height:auto !important;
			height:100% !important;
			.img{
				//height:;
			}
			img{
				min-height:auto !important;
				height:100% !important;
				width:100% !important;
				object-fit:cover;
			}
		}
	} */ 
}
#too-hero .too-slider{
	width:100%;
	height:100%;
	overflow:hidden;
	position:relative;
}
#too-hero .too-slider .slides .slide{
	width:100%;height:70%;
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	background-position:center center;
	background-size:cover;
}
#too-hero .too-slider .slides .slide img{
	display:block;
}
#too-hero .too-slider .controls a{
	width:40px;
	height:20px;
	position:absolute;
	top:50%;
	z-index:300;
	background-image:url("https://media.melia.com/toolib/components/hero/img/sprite.png");
	background-size:100%;
	background-repeat:no-repeat;
	-ms-transform:translateX(-50%) translateY(-50%) rotate(90deg);
	-moz-transform:translateX(-50%) translateY(-50%) rotate(90deg);
	-webkit-transform:translateX(-50%) translateY(-50%) rotate(90deg);
	transform:translateX(-50%) translateY(-50%) rotate(90deg)
}
#too-hero .too-slider .controls a.prev-slide{
	background-position:0 -565px;
	left:2rem;
}
#too-hero .too-slider .controls a.next-slide{
	background-position:0 -600px;
	right:0;
}
#too-hero .too-slider .dots{
	width:100%;
	position:absolute;
	bottom:2rem;
	z-index:300;
	text-align:center;
	padding-left:0;
}
#too-hero .too-slider .dots li{
	width:10px;
	height:10px;
	margin:0 .5rem;
	display:inline-block;
	vertical-align:middle
}
#too-hero .too-slider .dots li a{
	width:100%;
	height:100%;
	display:block;
	background-color:#fff;
	border:1px solid #353535;
	border-radius:50%;
	cursor:pointer;
}
#too-hero .too-slider .dots li a.dot-selected{
	background-color:#353535;
	border:2px solid #d5d5d5;
	box-shadow:0 0 0 2px #353535
}
@media (max-width:767px){
	#too-hero .too-slider .dots{
		display:none;
	}
}
.caption{
	font-weight: 700;
	font-family: 'avalon', sans-serif;
	position: absolute;
	top: 40%;
	left: 20%;
	right: 20%;
	bottom: inherit;
	z-index: 400;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0,0,0,0.6);
	background: rgba(66,66,66,0.5);
	h3{
		color: #fff;
		margin: 0 0 5px 0;
		font-size: 35px;
		text-transform: uppercase;
	}
}
span.stars.four:before {content: "\e62f\e62f\e62f\e62f";}
span.stars:before{
    display: inline-block;
    height: 14px;
    font-family: 'melia-icons';
    font-size: 24px;
}

.slide.img > img {
	width: calc(100vh * 1.777) !important;
  	min-width: 100vw !important;
	height: calc(100vw / 1.777) !important;
	min-height: 100vh !important;
  	margin: 0 !important;
  	position: relative;
  	left: 50%;
  	top: 50%;
  	transform: translateX(-50%) translateY(-50%);
}

.slide.img a > img {
	width: calc(100vh * 1.777) !important;
  	min-width: 100vw !important;
	height: calc(100vw / 1.777) !important;
	min-height: 100vh !important;
  	margin: 0 !important;
  	position: relative;
  	left: 50%;
  	top: 0;
  	transform: translateX(-50%) translateY(0);
}
.slides{
	.slides-text{
		position: absolute;
		margin: auto;
		color: #fff;
		display: block;
		text-transform: uppercase;
		width: 100%;
		height: 100%;
		z-index: 9999;
		text-align: center;
		&-container{
			position: relative;
			display: table;
			height: 100%;
			vertical-align: middle;
			width: 100%;
			margin:auto;
		}
		&-content{
			display: table-cell;
			height: 100%;
			width: 100%;
			vertical-align: middle;
		}
	
		.head{
			font-size: 42px;
			text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
			font-weight: bold;
			min-width: 600px;
			width:40%;
			margin:auto;
			margin-bottom:15px;
		}
		.sub-head{
			font-size: 25px;
			width:40%;
			min-width: 600px;
			margin:auto;
			margin-bottom:15px;
		}
		li{
			background: rgba(0,0,0,0.3); 
			display:inline-block;
			width:320px;
			height: 207px;
			vertical-align: top;
			padding:25px;
			.promotion-square{
				display:table;
				height:100%;
				width: 100%;
				text-align: center;
				.content{
					display:table-cell;
					vertical-align: middle;
					.from-main-promo{
						font-size: 31px;
					}
					.amount-main-promo{
						font-size: 62px;
						font-weight: bold;
						color: #f5a48f;
					}
					//promo 2
					.from-promo2{
						font-size:18px;
						font-weight: bold;
					}
					.amount-promo2{
						font-size:42px;
						font-weight: bold;
					}
					.copy-promo2{
						font-size:18px;
						font-weight: bold;
						.highlighted{
							color:#be846a;
						}
						position:relative;
						&:after{
							content:"";
							position: absolute;
							height: 1px;
							background-color: #fff;
							bottom: -10px;
							width: 50%;
							margin: auto;
							left: 0;
							text-align: center;
							display: inline-block;
							right: 0;
						}
					}
					.from-promo3{
						margin-top:20px;
						font-size: 24px;
					}
					.copy-promo3{
						font-size:38px;
						color:#be846a;
						font-weight: bold;
					}
				}
			}
		}
	} 
}

// Fondo Imagen Principal
.slides-text-container {
	height: 1024px!important;
}

// Flechas Slider versión White
@media (max-width: $screen-xs-max){
	.hero__buttons__white {
		display: none;
	}
}

.hero__buttons__white {
	position: absolute;
	z-index: 1000;
	top: 50vh;
	width: 100%;

	button {
		position: absolute;
		background-color: transparent;
    	border: none;
	}

	button:nth-child(2) {	
		right: 0;
	}

	button i {
		color: white;
		font-size: 70px;
		transition: opacity 0.3s;
	}

	button i:hover {
		opacity: 0.6;
	}

	button:focus {
		outline: none!important;
	}
}

.c-hotel-sheet-hero-test {
	height: 100%!important;
	.slides{
		height: 80vh;
		.slide{
			height: 100% !important;
			.slides-text{
				height: 100%;
				.slides-text-container{
					height: 100% !important;
				}
			}
		}
	}
}

.offer-home-hero {
	margin-top: 100px;
	text-transform: initial;
	letter-spacing: 0.6px;

	.offer-home-hero__logo {
		width: 100%;
		height: 50px;

		img{
			width: 100% !important;
			height: auto !important;
			margin-top: 0 !important;
		}
	}

	.offer-home-hero__title {
		font-size: 24px;
		font-weight: normal;
		letter-spacing: 5px;
	}

	div, h1 {
		color: #4a4a4a;
	}

	.offer-home-hero__separator {
		margin: 10px 0px 10px 0px;
		border: 0.5px solid $color-black;
	}

	.offer-home-hero__text1 {
		font-weight: bold;
		margin-top: 10px;
	}

	.offer-home-hero__text1, .offer-home-hero__text3 {
		font-size: 13px;
	}

	.offer-home-hero__hightlight {
		text-transform: uppercase;
		margin: 20px 0px 20px 0px;
		font-weight: bold;

		span {
			color: $color-discount;
			font-size: 35px;
			margin: 5px;
		}
	}

	.offer-home-hero__text3 {
		font-style: italic;
		margin-top: 10px;
	}
	@media (max-width: $screen-xs-max){
		// Tamaño movil
		.col-xs-12.visible-xs {
			background-color: rgba(255, 255, 255, 0.6);
			padding: 15px 15px 15px 15px;
	
			.offer-home-hero__title {
				letter-spacing: 2px;
				font-size: 20px;
				color: black;
				font-weight: bold;
			}
	
			.offer-home-hero__separator {
				margin: 15px 0px 20px 0px;
			}
	
			.col-xs-4.visible-xs {
				float: none;
				width: 35%;
				text-align: left;
				vertical-align: middle;
				text-align: left; 
				display: inline-block!important;
			}
	
			.col-xs-8.visible-xs {
				float: none;
				width: 65%;
				vertical-align: middle;
				margin-left: -5px;
				display: inline-block!important;
	
				.offer-home-hero__hightlight {
					text-align: right;
					margin: 0;
					font-size: 10px;
	
					span {
						font-size: 30px;
					}
				}
			}
	
			img {
				width: 100%!important;
				height: auto!important;
			}
			.offer-home-hero__button {
				a {
					color: black;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}
	&--white{
		.offer-home-hero__title {
			font-size: 24px;
			letter-spacing: 6px;
	
			span {
				font-weight: bold;
			}
		}
		.offer-home-hero__logo{
			img{
				width:auto !important;
				display:inline-block;
			}
		}
		.feature{
			margin-bottom:5px;
		}
		.offer-home-hero__separator {
			margin: 10px 0px 20px 0px;
		}
	
		.offer-home-hero__text3 {
			font-style: italic;
			margin-top: 15px;
		}
		.offer-home-hero__hightlight {
			text-transform: uppercase;
			margin: 20px 0px 20px 0px;
			font-weight: bold;
			span {
				color: #FC4C02;
				font-size: 35px;
				margin: 5px;
			}
		}
		
		// Desktop
		.hidden-xs.col-sm-5.col-md-4.col-lg-4 {
			background-color: $color-white;
			padding-bottom: 25px;
			padding-top: 15px;
		
				.offer-home-hero__logo{
					display:table;
					img{
						position:relative;
						top:10px;
						margin:auto;
						display:inline-block;
					}
				}
		
			.offer-home-hero__button {
				display: flex;
				justify-content: center;
				a {
					border: 0.5px solid $color-black;
					color: $color-black;
					padding: 15px 40px 15px 40px;
					font-weight: bold;
					transition: all 0.2s;
				}
		
				a:hover {
					background-color: $color-black;
					color: $color-white;
					font-weight: normal;
				}
			}
		}
	}
	&--black{
		.hidden-xs.col-sm-5.col-md-4.col-lg-4,
		.col-xs-12.visible-xs{
			width: 496px;
			background-color: rgba(0, 0, 0, 0.7);
			padding: 30px;
			position: absolute;
			bottom: 0;
			right: 0;
			.offer-home-hero__buttons{
				position: absolute;
				top: -48px;
				width: 98px;
				left: 0;
				height: 48px;
				button{
					width: 48px;
					float: left;
					height: 48px;
					background: none;
					border: none;
					background-color: rgba(0, 0, 0, 0.5);
					i{
						color: $color-white;
						font-size: 20px;
					}
				}
			}
			.offer-home-hero__rewards{
				.offer-home-hero__logo{
					width: 130px !important;
					height: 42px !important;
					display: table-cell !important;
					
				}
				.offer-home-hero__hightlight{
					font-size: 22px;
					font-weight: normal;
					text-transform: inherit;
					letter-spacing: 1.4px;
					display:table-cell;
					vertical-align: middle;
					text-align: right;
					text-align: right;
					color: $color-discount;
					width:330px;
					padding-left: 10px;
					line-height: 27px;
					span{
						font-size: 22px;
					}
				}
			}
			.offer-home-hero__title{
				font-size: 24px;
				letter-spacing: 1px;
				line-height: 30px;
				text-transform: uppercase;
				color: $color-white;
				text-align: left;
				font-weight: normal;
				margin: 10px 0 5px 0;
			}
			.offer-home-hero__separator{
				border: 0.5px solid #FFF;
				clear: both;
			}
			.offer-home-hero__content{
				.offer-home-hero__text1,
				.offer-home-hero__text3{
					font-size: 12px;
					letter-spacing: 1px;
					line-height: 25px;
					color: $color-white;
					text-align: left;
					font-weight: normal;
					font-style: inherit;
					margin: 0;
				}
				.offer-home-hero__button{
					display: block;
					text-align: left;
					margin-top: 25px;
					a{
						font-size: 16px;
						border: 0.5px solid $color-white;
						color: $color-white;
						padding: 12px 30px 10px 30px;
						&:hover{
							background-color: $color-white;
							color:$color-black;
						}
					}
				}
			}
		}
		.feature{
			color:$color-white;
			margin-bottom:5px;
			margin-top:5px;
			text-align: left;
		}
		@media (max-width: $screen-xs-max){
			width: 496px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 10px;
			.col-xs-12.visible-xs{
				position: relative;
			}
		}
		
		@media (max-width: $screen-xxs-max){
			width: 100%;
			.col-xs-12.visible-xs{
				width: 100%;
    			height: auto;
				.offer-home-hero__buttons{
					display: none;
				}
				.offer-home-hero__rewards{
					vertical-align: middle;
    				width: 100%;
    				display: table;
					.offer-home-hero__logo{
						display: table-cell !important;
						width: 30% !important;
						height: 30px !important;
						background-position: center;
						vertical-align: middle;
					}
					.offer-home-hero__hightlight{
						color: $color-white;
						text-transform: uppercase;
						font-size: 12px;
						margin: 0;
						font-weight: bold;
						vertical-align: middle;
						display: table-cell;
						text-align: right;
						line-height: 20px;
						position: relative;
						margin-left: -6px;
						font-weight: bold;
						width: 70%;
						span{
							font-size: 31px;
							position: relative;
    						top: 5px;
						}
					}
				}
				.offer-home-hero__separator{
					margin: 15px 0px 10px 0px;
				}
				.offer-home-hero__title{
					text-align: center;
					border-bottom: 0.5px solid #FFF;
					padding-bottom: 15px; 
					font-size: 30px;
    				font-weight: normal;
				}
				.offer-home-hero__content{
					.offer-home-hero__text1,
					.offer-home-hero__text3{
						display: none;
					}
					.offer-home-hero__button{
						width: 100%;
						text-align: center;
						margin-top: 30px;
						a{
							width: 100%;
							display: inline-block;
							padding: 25px 0;
						}
					}
				}
			}
		}
		
	}
	
}

#too-hero .too-slider {
	height: 100%;
    min-height: 600px !important;
}



/*a.c-header-rewards__logo:after {
    content: "\e92c";
    font-family: 'icons_melia_common';
    font-size: 85px;
    color: #fff;
    line-height: 48px;
}*/