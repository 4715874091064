@media (max-width: 767px){
    .c-header__nav-link.languages {
        display:inline !important;
    }
}
.c-call-me-back-integrated {
    @media only screen and (max-width: 768px) {
        width: 100% !important;      
        margin-bottom: 50px !important;   
        margin-top: 110px !important;   
    }
    
   
}
.c-error-page{
    max-width:1440px;
    &__photo{
        text-align:center;
        padding-top:50px;
        position:relative;
        @media only screen and (max-width: 768px) {
            //margin-bottom:20px;
        }
        img{
            max-width:100%;
        }
        &__title{
            font-size:52px;
            text-align:center;
            margin-top:66px;
            text-transform:unset;
            margin-bottom:10px;
            & + h5{
                margin-top:8px;
                font-size:28px;
                margin-bottom:30px;
                @media only screen and (max-width: 768px) {
                       margin-top: 35px;
                         margin-bottom: 0;

                }
            }
            @media only screen and (max-width: 768px) {
                /* For mobile phones: */
              
               position:relative;
               padding-top:15px;
               top:0;
               bottom:0;
               left:0;
               right:0;
               margin:auto;
               color:$color-white;
               font-size:24px !important;
               >div{
                   display:table;
                   height:100%;
                   width:100%;
                   color:$color-black;
                   >span{
                       display:table-cell;
                       vertical-align: middle;
                       padding:15px;
                   }
               }
            }
        }
        
    }
    .add-more{padding:15px 0 0 0;font-weight:700;text-transform:uppercase;margin-bottom:20px;}
    .add-more i{position:relative;top:3px;margin-right:10px;font-size:18px;}
    .add-more i.up {
        display: inline-block;
        transform: rotate(180deg);
    }
    &__title-telephone{
        margin:auto;
        display: inline-block;
        text-align: center;
        width: 100%;
        margin-top:12px;
        margin-bottom:12px;
         @media only screen and (max-width: 768px) {
         margin-top:0px;
         }
        .container-iconcallmeback{
            width:50px;
            height:50px;            
            margin:auto;
            background-color: $brand-primary;
            position:relative;
            border-radius:50%;
            -moz-border-radius:50%;
            -webkit-border-radius:50%;
            -o-border-radius:50%;
            

            @media only screen and (max-width: 768px) {
                width:30px;
                height:30px;
                display:inline-block;
                vertical-align: middle;
                margin-right:10px;
                
            }
            i{
                font-size: 26px;
                position: absolute;
                top: 12px;
                left: 12px;
                color:$color-white;
                @media only screen and (max-width: 768px) {
                    top: 8px;
                    left: 8px;
                    color: #fff;
                    font-size:15px;
                }
            }
        }
        h4{
            font-size:22px;
            font-weight:bold;
            @media only screen and (max-width: 768px) {
                display:inline-block;
                font-size:16px;
            }
        }
    }
}