// Hotels in world
.c-our-brands {

    h3 { text-align: center; }

    .tab-content { padding: 15px 0; }

    .nav-pills>li>a { padding: 10px 0; }

    .icon-granmelia--logo-effects {
        position: relative;
        top: -8px;
    }
    .icon-me--logo-effects {
        position: relative;
        top: 2px;
    }
    .icon-paradisus--logo-effects {
        position: relative;
        top: -3px;
    }
    .icon-melia--logo-effects {
        position: relative;
        top: -5px;
    }
    .icon-innside--logo-effects {
        position: relative;
        top: 4px;
    }
    .icon-tryp--logo-effects,
    .icon-sol--logo-effects {
        position: relative;
        top: -4px;
    }

    .nav-pills.nav-justified li {
      vertical-align: middle;
    }

    /* Versión obsoleta, actualizada por flex.
    // Fix caused because of different sizes not being calculated properly.
    .nav > li {
        &:nth-child(1) { width: 17.2857%; }
        &:nth-child(2) { width: 12.2857%; }
        &:nth-child(3) { width: 15.2857%; }
        &:nth-child(4) { width: 15.2857%; }
        &:nth-child(5) { width: 15.2857%; }
        &:nth-child(6) { width: 12.2857%; }
        &:nth-child(7) { width: 12.2857%; }
    }*/

    .nav { 
        display: flex;
        align-items: center;
    }

    .nav > li {
        flex: 1 1;
        padding: 0 7.5px;
    }
    
    .nav .active a { background-color: transparent !important }//Cosas del tema.
    
    .nav .active a::before {
        position: absolute;
        display: block;
        top: 57px;
        width: 0;
        height: 0; 
        content: "";
        background: none;
        border: 11px solid transparent;
        border-top: white 11px solid;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: $screen-xs-max) {
    .c-our-brands { display: none }
}
