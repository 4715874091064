.map-hotel {
  min-height: 186px;
  padding: 0px;
  margin: 0px;
  background-color: $color-white;
  text-align: right;
  font-family: "Lato", sans-serif;
  display: none;
  .cont {
    top: 0px;
    left: 0px;
    padding: 0px;
    margin: 0px;
    width: 438px;
    .h4 {
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: $color-dark-gray;
      margin: 0px;
      padding: 0px 0px 7px 0px;
      min-height: 26px;
      border-style: solid;
      border-width: 0px 0px 1px 0px;
      border-color: $color-light-gray;
      text-align: left;
      a {
        color: $color-black;
        text-decoration: none;
        text-transform: none;
        transition: color 0.5s;
        -webkit-transition: color 0.5s;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    .img-cont {
      float: left;
      position: relative;
      padding-top: 10px;
      width: 245px;
      img {
        width: 245px;
        height: 140px;
      }
      a {
        bottom: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        position: absolute;
        right: 5px;
        font-weight: 700;
        cursor: pointer;
      }
      .media-icon {
        background: $color-black;
        padding: 7px;
        font-size: 12px;
        i {
          display: inline-block;
          margin-left: 7px;
          font-size: 16px;
          position: relative;
          top: 2px;
          color: $color-white;
        }
      }
      .media-icon.media-only-icon i {
        margin-left: 0;
        position: static;
      }
    }
    .price {
      padding: 0px 0px 0px 260px;
      text-align: right;
      .now {
        font-size: 22px;
        font-weight: 700;
        color: $color-black;
        .from {
          text-transform: uppercase;
          margin-right: 5px;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
    p {
      text-align: right;
      padding: 0px 0px 0px 260px;
      margin: 0px;
      line-height: normal;
      font-size: 10px;
      color: $color-dark-gray;
      min-height: 37px;
      padding-right: 3px;
    }
    .conditions {
      padding: 5px 0px 10px 260px;
      min-height: 59px;
      span {
        display: block;
      }
    }
  }
}
