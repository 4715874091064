// Imagen con pastilla información
.c-image-info {
  position: relative;

  &__image {
    display: table;
    position: relative;
    width: 100%;
    vertical-align: top;
    overflow: hidden;

    .image-hotel {
      object-fit: cover;
      -o-object-fit: cover;
      font-family: "object-fit: cover;";
      width: 100%;
      height: 100%;
      min-height: 400px;
    }
  }

  &__info {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    margin-left: -300px;
    padding: 15px 40px 25px 40px;
    color: $color-white;
    text-align: center;
    z-index: 1;
    margin-top: -117px;
    background-color: rgba(56, 56, 56, 0.45);

    h4 {
      padding-bottom: 5px;
      margin: 0 0 10px;
      border-bottom: 1px solid $color-white;
      font-size: 22px;
      font-weight: 700;
      color: $color-white;
      text-transform: uppercase;
    }

    p {
      font-size: 18px;
      line-height: 1.2;
    }
  }
}
