// Cookies disclaimer
.c-cookies-disclaimer {
    position: fixed;
    display: none;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    color: $color-white;
    background: $brand-primary;
    z-index: 999;
    h6 {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        color: $color-white;
    }
    p {
        font-weight: 300;
        a {
            color: $color-white !important;
            text-decoration: underline;
        }
    }
    i.icon-close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $color-white;
        cursor: pointer;
    }
}