// Footer component
.c-footer {
    background: $color-white;
    font-size: 14px;
    height: auto;
    padding: 0;

    .container { min-height: auto !important; }
	
    &__columns {

        .container {
            padding: 20px 0 0;
            border-top: 1px solid $color-gray;
        }

        h5 {
            text-transform: uppercase;
            font-size: 1em;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }

    h3 { margin-top: 20px; }

	h4 { 
        color: $color-black;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    ul li { line-height: 1.5em; }
	
    a {
        color: $color-black;
        text-decoration: none;
	}  
}
@media (max-width: $screen-xs-max) {
    .c-footer{
        &__columns { 

            .u-col-xs-5ths {
                width: auto;
                float: none;
            }
            
            h5 {
                font-size: 1em;
                margin-bottom: 5px;
                margin-top: 35px;
            }

            a { color: $color-dark-gray }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .c-footer__columns { margin: 0; }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .c-brands .container { width: auto; }
}

@media (min-width: $screen-md-min) {
    .c-brands__link i { font-size: 2.5em; }
    .icon-me--logo-effect::before { font-size: 48px; }
    .c-brands__link i.icon-paradisus--logo-effect { font-size: 3.2em; }
    .icon-innside--logo-effect::before { font-size: 48px; }
    .c-brands .c-brands__link .icon-sol--logo-effect { font-size: 2.8em; }
    .c-brands__link i.icon-circle-vertical--logo-effect { font-size: 4.5em; }
    .icon-rewards--logo-effect::before { font-size: 55px; }
}