// More options component
.c-more-options-home {
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;

  h3 {
    text-align: center;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    margin-bottom: 15px;
  }

  &__option {
    height: 100%;
    background-color: $color-white;
  }

  &__option-image-holder {
    position: relative;
    height: 220px;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    img,
    picture {
      height: 100%;
      width: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }

  &__option-title-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }

  &__option-title {
    text-align: center;
    margin: 0;
    color: $color-white;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__option-content {
    padding: 0px 15px 55px;
    position: relative;
  }

  &__option-text {
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: normal;
  }

  &__option-btn {
    width: 100%;
  }

  &__option-footer {
    padding: 10px;
    background-color: $color-white;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .c-more-options-home__option.app
    .c-more-options-home__option--image
    .caption
    .caption-inner
    .middle-content {
    top: 30% !important;
  }
}

@media (max-width: 1199px) {
  .c-more-options-home__option--footer p {
    height: 85px;
  }
}

@media (min-width: 1200px) {
  .c-more-options-home__option--footer p {
    height: 70px;
  }
}
