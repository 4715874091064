// Public header component
.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  padding: 0;
  background-color: transparent;
  color: $color-white;
  z-index: 1000;
  transition: top 0.5s ease;

  &__melia-bar {
    height: 30px;
    text-align: center;
    overflow: hidden;
    transition: height 0.5s;

    a {
      line-height: 26px;
    }
  }

  & > .container {
    height: 50px;
  }

  &__logo {
    float: left;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &__nav {
    float: right;
    height: 48px;

    &-link {
      padding: 20px 10px 19px;
      background-color: transparent;
      color: $color-white;
      line-height: 40px;
      text-decoration: none;
      outline: none;

      i {
        position: relative;
        top: 2px;
        font-size: 16px;
        margin-right: 8px;
      }

      span {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      &::after {
        position: relative;
        top: 3px;
        font-family: "icons_melia_common";
        font-size: 18px;
        content: "\e908";
        text-indent: 0;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $color-light-gray;
      }

      &--active {
        background-color: $color-light-gray;
      }
    }
  }

  &__menu-login {
    display: none;
    width: 100%;
    max-height: calc(100vh - 50px);
    padding: 15px 0;
    background-color: $color-light-gray;
    border-bottom: 1px solid $color-dark-gray;
    overflow-y: auto;
    z-index: 10;
    color: $color-dark-gray;

    h4,
    h5 {
      color: $color-dark-gray;
      text-align: center;
    }

    h4 {
      margin-bottom: 22px;
    }

    h5 {
      margin: 37px 0 8px;
      text-transform: none;
    }

    span {
      color: $color-dark-gray;
      text-transform: uppercase;
    }

    .menu-login-social {
      margin-bottom: 30px;

      .btn-google i {
        font-size: 20px;
      }
      .btn-facebook i {
        font-size: 24px;
      }

      .btn-facebook,
      .btn-google {
        height: 44px;
        padding: 6px;
        line-height: 28px;

        i {
          position: relative;
          top: 5px;
          left: -20px;
        }
      }
    }

    .menu-active-account,
    .menu-login-new-account {
      text-align: center;
    }
    .menu-login-new-account {
      margin-bottom: 15px;
    }

    .menu-active-account-now button.btn-default,
    .menu-remember-pwd button.btn-default {
      margin-top: 10px;
    }

    .menu-login-email {
      margin-bottom: 15px;

      .links-login {
        display: inline-block;
        margin-left: 10px;
        text-align: left;

        a {
          display: block;

          &:first-child {
            margin: 15px 0 0;
          }
          &:last-child {
            margin: 5px 0 0;
          }
        }
      }

      .form-horizontal {
        margin-left: 5px;
        .form-group {
          margin-bottom: 0;
        }
      }
    }

    .notice {
      margin-top: 15px;

      p {
        margin: 0;
      }
    }
  }

  &__menu-phones {
    display: none;
    width: 100%;
    max-height: calc(100vh - 50px);
    padding: 15px 0;
    background-color: $color-light-gray;
    border-bottom: 1px solid $color-dark-gray;
    overflow-y: auto;
    z-index: 10;
    color: $color-dark-gray;
    //max-height: calc(100vh - 75px);

    .title {
      padding: 6px 0;
      font-weight: 400;
      text-transform: uppercase;

      box-shadow: 0 2px 0 -1px $color-gray, 0 3px 0 -1px $color-white;

      p {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
    }

    .add-more {
      padding: 15px 0 0 0;
      font-weight: 700;
      text-transform: uppercase;

      i {
        position: relative;
        top: 3px;
        margin-right: 10px;
        font-size: 18px;

        &.up {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }
  }

  //&--white &__menu-phones { max-height: calc(100vh - 45px); }

  &__menu-languages {
    display: none;
    width: 100%;
    padding: 15px 0;
    background-color: $color-light-gray;
    border-bottom: 1px solid $color-dark-gray;
    overflow-y: auto;
    z-index: 10;
    color: $color-dark-gray;

    .title {
      padding: 6px 0;
      font-weight: 400;
      text-transform: uppercase;

      box-shadow: 0 2px 0 -1px $color-gray, 0 3px 0 -1px $color-white;

      p {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
    }

    .line:hover {
      background-color: $color-gray;
      cursor: pointer;
    }

    .list-languages {
      width: 100%;
      padding: 0;
      margin: 0;

      ul {
        padding: 0;
        margin: 0;

        li:hover {
          background-color: $color-light-gray;
          cursor: pointer;
        }

        a {
          display: block;
          margin: 0;
          padding: 15px 5px;
          font-size: 13px;
        }
      }
    }
  }

  // Icono hamburguesa
  &__icon-mobile-menu {
    position: relative;
    float: left;
    padding: 12px 0 0;
    margin-right: 12px;
    background-color: transparent;
    color: $color-white;
    border: 0;
    font-size: 25px;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    i {
      border-right: 1px solid $color-white;
      padding-right: 12px;
      font-size: 27px;
    }

    span {
      position: relative;
      top: -5px;
      margin: 0 2px 0 10px;
    }
  }

  // Menú hamburguesa
  &__mobile-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $color-white;
    z-index: 9999;
    overflow-y: auto;

    &__melia-bar {
      height: 30px;
      text-align: center;
      overflow: hidden;
      transition: all 1s;

      + .container {
        height: 50px;
        padding: 2px 15px;
      }

      a {
        line-height: 26px;
      }
    }

    &__close {
      float: left;
      position: relative;
      height: 41px;
      width: 41px;
      margin-right: 10px;
      border: 0;
      border-radius: 100px;
      color: $color-black;
      background-color: transparent;
      font-size: 16px;
      outline: 0;

      i {
        position: relative;
        top: 3px;
        left: -5px;
        font-size: 20px;
      }

      &::after {
        position: absolute;
        right: 2px;
        top: 8px;
        width: 1px;
        height: 28px;
        background-color: $color-dark-gray;
        content: "";
      }
    }

    &__logo {
      // El logo se carga desde el Theme
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    & > .c-header__mobile-menu--options {
      padding: 22px;
      border-top: 1px solid $color-dark-gray;

      li {
        line-height: 30px;
        text-align: center;

        a {
          text-decoration: none;
        }
      }
    }

    & > .c-header__mobile-menu--languages {
      display: none;
      padding: 22px;
      border-top: 1px solid $color-dark-gray;

      li {
        line-height: 30px;
        text-align: center;

        a {
          text-decoration: none;
        }
      }

      .c-header__mobile-menu__link {
        text-transform: initial;
        color: $color-black;
      }

      .c-header__mobile-menu__link.languages {
        text-transform: uppercase;
        color: $brand-primary;
      }
    }

    &__link {
      display: block;
      padding: 15px;
      background-color: transparent;
      font-size: 25px;
      text-transform: uppercase;
      line-height: 1.25;
      transition: background-color 0.5s, color 0.5s;

      &--gray {
        display: block;
        padding: 15px;
        background-color: transparent;
        color: $color-dark-gray !important;
        font-size: 25px;
        text-transform: uppercase;
        line-height: 1.25;
        transition: background-color 0.5s, color 0.5s;

        &:hover,
        &:active {
          color: $color-white !important;
          background-color: $brand-primary;
          transition: background-color 0.5s, color 0.5s;
        }
      }

      &:hover {
        color: $color-white !important;
        background-color: $brand-primary;
        transition: background-color 0.5s, color 0.5s;
      }
    }
  }

  // Header con fondo blanco
  &--white {
    background-color: $color-white;
    color: $color-black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.13);
    transition: background-color 1s, background-image 2s;

    .c-header__melia-bar,
    .c-header__mobile-menu__melia-bar {
      height: 0;
      transition: height 0.5s;
    }

    .btn {
      border-width: 1px;
    }

    .c-header__show-menu {
      color: darken($color-dark-gray, 9%);
    }

    .c-header__phones {
      &:focus,
      &:hover {
        text-decoration: none;
      }

      span {
        color: $color-black;
      }
    }

    .c-header__menu-login,
    .c-header__menu-phones,
    .c-header__menu-languages {
      top: 46px;
    }

    .c-header__nav-link span {
      color: $color-black;
    }
  }

  &--no-transition {
    transition: none;
  }

  .form-control {
    height: 44px;
  }

  .line {
    box-shadow: 0 2px 0 -1px $color-gray, 0 3px 0 -1px $color-white;
    display: block;
    margin: 0;
    padding: 15px 5px;
    font-weight: 700;
    font-size: 13px;

    span {
      font-weight: 300;
    }
  }

  &--melia-basic-logo &__logo {
    height: 50px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: -3px;
  }
}

// Resolución mobile
@media (max-width: 767px) {
  .c-header__nav-link.languages {
    display: none;
  }

  .c-header--melia-basic-logo .c-header__logo,
  .c-header__mobile-menu__logo {
    display: inline-block;
    overflow: hidden;
    width: 90px;
  }

  .c-header__mobile-menu__link,
  .c-header__mobile-menu__link--gray {
    padding: 10px;
    font-size: 18px;
  }

  .c-header__menu-login {
    hr {
      border-color: #ddd;
    }

    .menu-login-email {
      .col-sm-5 input {
        margin-bottom: 10px;
      }
      .btn-login {
        margin-top: 10px;
      }
    }

    .menu-login-social {
      .btn-facebook {
        margin-bottom: 10px;
      }
      .col-sm-6 {
        padding-left: 8px;
        padding-right: 0;
      }
    }

    .c-header__menu-login
      .menu-login-email
      .form-horizontal
      .form-group
      i.error,
    .c-header__menu-login
      .menu-login-email
      .form-horizontal
      .form-group
      i.valid {
      top: 16px;
    }
  }
}

// Resolución a partir de tablet
@media (min-width: 768px) {
  .c-header__mobile-menu .link-languages {
    display: none;
  }

  #blockLoggin .menu-login-social {
    .col-sm-6:nth-child(2) {
      padding-left: 0;
    }
    .col-sm-6:nth-child(3) {
      padding-right: 0;
    }
  }

  #blockLoggin .menu-login-email {
    .col-sm-5 {
      padding-left: 8px;
      padding-right: 0px;
    }

    .col-sm-4 {
      padding-right: 0px;
    }
  }

  .c-header__menu-login .menu-login-email .form-horizontal .form-group i.error,
  .c-header__menu-login .menu-login-email .form-horizontal .form-group i.valid {
    top: 16px;
    right: 16px;
  }
}

// Resolución mobile y tablet
@media (max-width: 991px) {
  .c-header > .container,
  .c-header__menu-login > .container {
    margin-right: initial;
    margin-left: initial;
    width: 100%;
  }

  .c-header__menu-login,
  .c-header__menu-phones,
  .c-header__menu-languages {
    top: 46px;
  }

  .c-header__menu-login .menu-active-account,
  .c-header__menu-login .menu-login-new-account {
    margin-bottom: 30px;
    padding: 0 20px;
  }

  .c-header__nav-link i {
    margin-right: 0;
  }
}

// Resolución desktop
@media (min-width: 992px) {
  .c-header__menu-login .divisor {
    border-right: 1px solid $color-gray;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn-login {
    padding: 0;
  }

  #blockLoggin .menu-login-social {
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  #blockLoggin .menu-login-email .col-sm-4 {
    padding-right: 0px;
  }
}
