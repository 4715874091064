//Title hotel sheet intro with logo
.c-hotel-sheet-email {
    color: $color-white;
    h3 {
        margin: 0 0 5px 0;
        color: $color-white;
        line-height: 40px;
    }
    p {
        margin-bottom: 18px;
        font-size: 18px;
    }
    &__form {
        .form-group {
            position: relative;
            float: left;
            width: 79%;
            margin-right: 1%;
            input {
                padding-left: 40px;
            }
            i {
                position: absolute;
                left: 10px;
                top: 22%;
                font-size: 24px;
                color: $color-dark-gray;
            }
        }
        .btn {
            width: 20%;
        }
        .advert {
            clear: both;
            font-size: 14px;
            a {
                color: $color-white;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .c-hotel-sheet-email {
        &__form {
            .form-group {
                width: 100%;
                margin: 0;
                margin-bottom: 15px;
            }
            .btn {
                width: 100%;
            }
            .advert {
                text-align: left;
                margin-top: 15px;
            }
        }
    }
}